<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- soon -->
      <b-card>
        <banca-logo style="width:100%" />
        <b-card-title>
          Enhancing Our Products for a Better User Experience
        </b-card-title>
        We are diligently enhancing our products to ensure an improved functionality and an enhanced user experience.
        <br><br>We appreciate your patience and anticipate that our platform will be available soon. Thank you for your understanding.
        <br><br>
        Sincerely,<br>
        BCA Team
        <!-- <b-button
          v-ripple.400="'rgba(114, 47, 10, 0)'"
          variant="outline-success"
          :style="'background-color:white'"
          block
          disabled
        >
          Bancannabis DAPP
          <img
            :src="require('@/assets/images/logo/logo.png')"
          >
        </b-button> -->

        <!-- submit button -->
        <!-- <b-button
          v-ripple.400="'rgba(114, 47, 10, 0)'"
          variant="outline-success"
          :style="'background-color:white'"
          block
          disabled
          @click="successLogin"
        >
          NFT Cannaverse (Coming soon)
          <img
            style="width:20%"
            :src="require('@/assets/images/logo/logo-cannaverso.png')"
          >
          <b-spinner
            v-show="doMetamaskRequest"
            small
          />
        </b-button> -->
        <!-- Showroom
        <b-button
          variant="primary"
          block
          @click="downloadProxLauncher"
        >
          Bancannabis Showroom UE &nbsp;&nbsp;&nbsp;
          <metamask-logo /> &nbsp;&nbsp;&nbsp;
          <b-spinner
            v-show="doMetamaskRequest"
            small
          />
        </b-button>-->
        <!--
          <b-card-text class="text-center mt-2">
            <span>New on our platform? </span>
            <b-link :to="{name:'auth-register'}">
              <span>Create an account</span>
            </b-link>
          </b-card-text>
          <b-card-text class="text-center mt-2" v-show="!showBancannabisAuth">
            <span>¿How to add BSC network in Metamask? </span>
            <b-link @click="openBscInfo"><br>
              <span>Click to see how</span>
            </b-link>
          </b-card-text>
          -->
      </b-card>
      <!-- /Login v1 -->
    </div>

    <!-- how add bsc to metamask -->
    <b-modal
      id="modal-bsc-to-metamask"
      title="Add to Metamask the Binance Smart Chain (Tutorial)"
      ok-only
      ok-title="Ok"
    >
      <b-card-text>
        <div
          class="vx-col w-full"
          style="border: 1px solid forestgreen; margin:1px; text-align:justify;height: 400px;overflow-y: scroll;"
        >
          <div style="width:100%; text-align:center">
            <b-img
              :src="require(`@/assets/images/pages/metamaskicon.png`)"
              class="product-img"
              fluid
            />
            <logo-bnb style="width:50px" />&nbsp;
          </div>
          <p>&nbsp;</p>
          <p class="text-center">
            <strong>Add Binance Network (BSC) in the following simple steps:</strong>
          </p>
          <p>
            <span>
              <b-img
                :src="require(`@/assets/images/pages/login/binance-metamask-begin.png`)"
                class="product-img w-80"
                fluid
              />
            </span>
          </p>
          <p>&nbsp;</p>
          <p class="text-center">
            <strong>
              now you can access with the Metamask option.
              <br> Let's Plant!
            </strong>
          </p>
        </div>
      </b-card-text>
    </b-modal>

  </div>
</template>

<script>
import {
  BImg, BCard, BCardTitle, BCardText,
} from 'bootstrap-vue'
import bancaLogo from '@core/layouts/components/HeaderLoginNft.vue'
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import logoBnb from '@core/layouts/components/logoBnb.vue'

export default {
  components: {
    // BSV
    BCard,
    BCardTitle,
    // VuexyLogo,
    BCardText,
    BImg,
    logoBnb,
    bancaLogo,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    // setTimeout(() => {
    // Remove userData from localStorage
    // localStorage.removeItem('userData')
    // Redirect to login page
    // this.$router.push({ name: 'login' })
    // }, 5000)

    return {
      userEmail: '',
      password: '',
      status: '',
      doLoadingRequest: false,
      // validation rules
      required,
      email,
      invData: {},
      invitationCode: this.$route.query.code, // ingresar con codigo de invitacion
      responseAuth: null,
      reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      // mostrar boton metamask (Solo navegadores NO MOBILES)
      showMetamaskButton: true,
      // saber si se esta solicitando loguear con metamask
      doMetamaskRequest: false,
      metamaskData: null,
      metamaskSignature: null,
      metamaskUserNonce: 0,

      // si se da click en metamask entonces ocultara el formulario de logueo
      showBancannabisAuth: false, // Toggle entre bancannabis auth o metamask
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    successLogin() {
      this.$router.push({ name: '/' })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
